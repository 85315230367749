<template>
  <div v-if="withCondition">
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.sequence_flow.condition') }}
      </div>
      <el-input size="mini" v-model="localModel"></el-input>
    </div>
  </div>
</template>

<script>
import mixins from '../mixins'
import { is } from 'bpmn-js/lib/util/ModelUtil'

export default {
  name: 'SequenceFlowEditor',
  inject: ['getModeler'],
  mixins: [mixins],
  data () {
    return {
      localModel: null
    }
  },
  mounted () {
    this.localModel = this.element.businessObject?.conditionExpression?.body || ''
  },
  methods: {
    changeCondition (condition) {
      if ((condition || '').trim().length > 0) {
        const moddle = this.getModeler().get('moddle')
        const cond = moddle.create('bpmn:Expression', { type: 'tFormalExpression', body: condition.toString() })

        this.getModeler().get('modeling').updateProperties(this.element, {
          conditionExpression: cond
        })
      }
    }
  },
  watch: {
    localModel (value) {
      this.changeCondition(value)
    }
  },
  computed: {
    withCondition () {
      return is(this.element?.businessObject?.sourceRef, 'bpmn:ExclusiveGateway')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../main";
</style>
